<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div id="moreinfo"></div>
    <div class="my-4">
      <h2 class="font-semibold text-lg text-center">
        Event- {{ $route.params.eventName }} (City-
        {{ this.$route.params.city }})
      </h2>
    </div>
    <div class="flex my-4">
      <button
        class="bg-green-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4"
        @click="getTixs('AvailableTixs', 'Available Tixs', '#8FEAC7')"
      >
        Available Tixs
      </button>
      <button
        class="bg-red-400 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4"
        @click="getTixs('TixSoldInDateRange', 'Sold Tixs', '#ffa5a5')"
      >
        Sold Tixs
      </button>
      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4"
        @click="getTixs('AvgSalePrice', 'Avg Sale Price', '#fbdb74')"
      >
        Avg Sale Price
      </button>
      <!-- <button
        class="bg-indigo-500 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4"
        @click="Social()"
      >
        Social Media
      </button> -->

      <button
        class="bg-indigo-500 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4"
        @click="moreshow()"
      >
        More info
      </button>

      <div style="display: flex;">
        <a
          class="cursor-pointer text-blue-600"
          :href="
            'https://www.facebook.com/events/search/?q=' +
              $route.params.eventName
          "
          target="_blank"
          ><img
            src="@/assets/img/landing/facebook.png"
            alt="img"
            style="max-width: 30px;"
        /></a>
      </div>
    </div>

    <div class="flex my-4">
      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialblade($route.params.eventName)"
      >
        Social Blade (YouTube)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladetwitter($route.params.eventName)"
      >
        Social Blade (Twitter)
      </button>

      <!-- <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladetwitch($route.params.eventName)"
      >
        Social Blade (Twitch)
      </button> -->

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladeinstagram($route.params.eventName)"
      >
        Social Blade (Instagram)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladettiktok($route.params.eventName)"
      >
        Social Blade (TikTok)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladefacebook($route.params.eventName)"
      >
        Social Blade (Facebook)
      </button>
    </div>

    <!-- <div class="flex my-4">
      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladetrovo($route.params.eventName)"
      >
        Social Blade (Trovo)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladestoryfire($route.params.eventName)"
      >
        Social Blade (StoryFire)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladedlive($route.params.eventName)"
      >
        Social Blade (DLive)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladedailymotion($route.params.eventName)"
      >
        Social Blade (DailyMotion)
      </button>

      <button
        class="bg-yellow-300 bg-opacity-50 hover:bg-opacity-75 rounded-full font-semibold py-2 mx-4 w-1/4 text-center px-4 fs-small"
        @click="socialbladeodysee($route.params.eventName)"
      >
        Social Blade (Odysee)
      </button>
    </div> -->

    <div class="container mx-auto">
      <LineChart
        :chartData="datacollection"
        :options="{ responsive: true, maintainAspectRatio: false }"
      />
    </div>
    <div class="my-16">
      <div class="my-4">
        <h2 class="font-semibold text-lg text-center">
          Event- {{ $route.params.eventName }} (City-
          {{ this.$route.params.city }})
        </h2>
      </div>
      <table v-if="isGraphShow" class="border-collapse w-full text-sm">
        <TableHeaderGraph />
        <tbody id="tixChartList">
          <tr
            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              colspan="9"
            >
              {{ loading }}
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="!isGraphShow" class="border-collapse w-full text-sm">
        <TableHeaderChart />
        <tbody id="tixChartListToday">
          <tr
            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              colspan="9"
            >
              {{ loading }}
            </td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex;">
        <div style="height: 650px; width: 48%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Wikipedia
            </b>
            <i
              class="fa fa-refresh"
              @click="
                openUrlWithLazy(
                  'loadwiki',
                  'https://en.wikipedia.org/wiki/' + $route.params.eventName
                )
              "
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="loadwiki">
            <!-- <iframe v-lazy="'https://en.wikipedia.org/wiki/Steve Martin &amp; Martin Short'" @load="onLoad" width="100%" height="650" /> -->
          </div>
        </div>
        <div style="height: 650px; width: 48%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Youtube
            </b>
            <i
              class="fa fa-refresh"
              @click="youtube()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="youtube"></div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Spotify
            </b>
            <i
              class="fa fa-refresh"
              @click="spotify()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="spotify-date"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Spotify followers List
            </b>
            <i
              class="fa fa-refresh"
              @click="spotify()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="spotify-data"></div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 48%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Billboard
            </b>
            <i
              class="fa fa-refresh"
              @click="
                openUrlWithLazy(
                  'billboard-ifrm',
                  'https://varilytics.com/api/billboard?q=' +
                    $route.params.eventName
                )
              "
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>

          <div id="billboard-ifrm">
            <!-- <iframe v-lazy="'https://varilytics.com/api/billboard?q='+$route.params.eventName+''" @load="onLoad" width="100%" height="650" > </iframe> -->
          </div>
        </div>
        <div style="width: 50%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Map
            </b>
            <!-- <i
              class="fa fa-refresh"
              @click="
                openUrlWithLazy(
                  'map-ifrm',
                  'https://varilytics.com/spaceseat/map/?name=' +
                    $route.params.eventName
                )
              "
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i> -->
          </h2>
          <div id="map-ifrm">
            <!-- <iframe v-lazy="'https://varilytics.com/spaceseat/map/?name='+$route.params.eventName+''" @load="onLoad" width="100%" height="650" > </iframe> -->
            <gmap-map
              :center="center"
              :zoom="4"
              style="width:100%;  height: 620px"
            >
              <gmap-marker
                :key="index"
                :label="gmp.label"
                v-for="(gmp, index) in locations"
                :position="gmp"
                :clickable="true"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: auto; width: 100%; overflow: scroll;">
          <h2 class="text-center" style="line-height: 2.7;">
            <b>
              Interest By Sub Region 7 Days
            </b>
            <i
              class="fa fa-refresh"
              @click="trend7d()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="intbsrd-ifrm"></div>
          <BarChart
            v-if="showCompTrend7d"
            :chartData="dcompTrend7d"
            :options="{ responsive: true, maintainAspectRatio: false }"
          />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: auto; width: 100%; overflow: scroll;">
          <h2 class="text-center" style="line-height: 2.7;">
            <b>
              Interest By Sub Region 12 Months
            </b>
            <i
              class="fa fa-refresh"
              @click="trend1y()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="intbsrm-ifrm"></div>
          <BarChart
            v-if="showCompTrend1y"
            :chartData="dcompTrend1y"
            :options="{ responsive: true, maintainAspectRatio: false }"
          />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: auto; width: 100%; overflow: scroll;">
          <h2 class="text-center" style="line-height: 2.7;">
            <b>
              Interest By Sub Region
            </b>
            <i
              class="fa fa-refresh"
              @click="trend5y()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="intbsr-ifrm"></div>
          <BarChart
            v-if="showCompTrend5y"
            :chartData="dcompTrend5y"
            :options="{ responsive: true, maintainAspectRatio: false }"
          />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: auto; width: 100%; overflow: scroll;">
          <h2 class="text-center" style="line-height: 2.7;">
            <b>
              Interest Over Time
            </b>
            <i
              class="fa fa-refresh"
              @click="interestOverTime()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="intot-ifrm"></div>
          <BarChart
            v-if="showCompIoverTime"
            :chartData="dcompIoverTime"
            :options="{ responsive: true, maintainAspectRatio: false }"
          />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: auto; width: 100%; overflow: scroll;">
          <div class="mb-2 text-center">
            <input
              class="shadow appearance-none border rounded py-2 px-3 text-grey-darker"
              id="name"
              type="text"
              style="width: auto;
            margin-right: 10px;"
              placeholder="Comparison artist"
              v-model="aname"
            />
            <p class="text-red text-xs italic" v-if="error && !aname">
              Please enter a artist name.
            </p>
            <button
              @click="compareInterest()"
              class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
              type="button"
            >
              Compare
            </button>
          </div>
          <div id="intot-comp"></div>
          <!-- <canvas id="trendsChart"></canvas> -->
          <BarChart
            v-if="showComp"
            :chartData="dcomp"
            :options="{ responsive: true, maintainAspectRatio: false }"
          />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Top 100: USA
            </b>
            <i
              class="fa fa-refresh"
              @click="musictop100usa()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="music100usa"></div>
        </div>

        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Top 100: Global
            </b>
            <i
              class="fa fa-refresh"
              @click="musictop100global()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="music100global"></div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Music Apple Top
            </b>
            <i
              class="fa fa-refresh"
              @click="musicappletop()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="musicappletop"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Music Apple International Top
            </b>
            <i
              class="fa fa-refresh"
              @click="musicappleint()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="musicappleinternationaltop"></div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Chat GPT Promt 1
            </b>
            <i
              class="fa fa-refresh"
              @click="chatgpt()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="chatgptdatapromt1"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Chat GPT Promt 2
            </b>
            <i
              class="fa fa-refresh"
              @click="chatgpt()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="chatgptdatapromt2"></div>
        </div>
      </div>

      <!-- <div style="display: flex;">
         
        <div style="height: 650px; width: 98%; overflow: scroll;"> 
           <div id="youtubecity"> 
              No Data found
          </div>
       </div>
         <div style="height: 650px; width: 98%; overflow: scroll;"> 
           <div id="youtubechat"> 
              No data found
           </div>
       </div>
     </div> -->

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Related Topics
            </b>
            <i
              class="fa fa-refresh"
              @click="
                openUrlWithLazy(
                  'relatedtopic-ifrm',
                  'https://varilytics.com/spaceseat/related_topics.php?event=' +
                    $route.params.eventName
                )
              "
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="relatedtopic-ifrm">
            <!-- <iframe v-lazy="'https://varilytics.com/spaceseat/related_topics.php?event='+ $route.params.eventName" @load="onLoad" width="100%" height="650" > </iframe> -->
          </div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Related Queries
            </b>
            <i
              class="fa fa-refresh"
              @click="
                openUrlWithLazy(
                  'relatedquery-ifrm',
                  'https://varilytics.com/spaceseat/related_queries.php?event=' +
                    $route.params.eventName
                )
              "
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="relatedquery-ifrm">
            <!-- <iframe v-lazy="'https://varilytics.com/spaceseat/related_queries.php?event='+ $route.params.eventName" @load="onLoad" width="100%" height="650" > </iframe> -->
          </div>
        </div>
      </div>

      <div v-if="$route.params.category == 'SPORTS'">
        <div style="display: flex;">
          <div style="height: 650px; width: 98%; overflow: scroll;">
            <h2 class="text-center">
              <b>
                Google Result 1
              </b>
              <i
                class="fa fa-refresh"
                @click="googleresultapi()"
                style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
              ></i>
            </h2>
            <div id="googleresultapi"></div>
          </div>
          <div style="height: 650px; width: 98%; overflow: scroll;">
            <h2 class="text-center">
              <b>
                Google Result 2
              </b>
              <i
                class="fa fa-refresh"
                @click="googleresultapidata()"
                style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
              ></i>
            </h2>
            <div id="googleresultapidata"></div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Billboard Hot 100
            </b>
            <i
              class="fa fa-refresh"
              @click="Billboardhot100()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="Billboardhot100"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Billboard 200
            </b>
            <i
              class="fa fa-refresh"
              @click="Billboard200()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="Billboard200"></div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Artist 100
            </b>
            <i
              class="fa fa-refresh"
              @click="Artist100()"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="Artist100"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center">
            <b>
              Calendar
            </b>
          </h2>
          <v-calendar :attributes="attributes" />
        </div>
      </div>

      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbyScroll">
            <b>
              Social Blade (Youtube)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialblade($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialblade"></div>
        </div>

        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbtScroll">
            <b>
              Social Blade (Twitter)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladetwitter($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladetwitter"></div>
        </div>
      </div>
      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbtkScroll">
            <b>
              Social Blade (TikTok)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladettiktok($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladettiktok"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbfbScroll">
            <b>
              Social Blade (Facebook)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladefacebook($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladefacebook"></div>
        </div>
      </div>
      <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbinScroll">
            <b>
              Social Blade (Instagram)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladeinstagram($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladeinstagram"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="ytmuScroll">
            <b>
              Music Charts & Insights
            </b>
            <i
              class="fa fa-refresh"
              @click="youtubeMusic($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="youtubemusic"></div>
        </div>
      </div>
      <!-- <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbtrScroll">
            <b>
              Social Blade (Trovo)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladetrovo($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladetrovo"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbsfScroll">
            <b>
              Social Blade (StoryFire)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladestoryfire($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladestoryfire"></div>
        </div>
      </div> -->
      <!-- <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbdlScroll">
            <b>
              Social Blade (DLive)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladedlive($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladedlive"></div>
        </div>
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbdmScroll">
            <b>
              Social Blade (DailyMotion)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladedailymotion($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladedailymotion"></div>
        </div>
      </div> -->
      <!-- <div style="display: flex;">
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbodScroll">
            <b>
              Social Blade (Odysee)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladeodysee($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladeodysee"></div>
        </div> 
        <div style="height: 650px; width: 98%; overflow: scroll;">
          <h2 class="text-center" ref="sbtwScroll">
            <b>
              Social Blade (Twitch)
            </b>
            <i
              class="fa fa-refresh"
              @click="socialbladetwitch($route.params.eventName)"
              style="font-size:1.3em; color:yellowgreen;cursor: pointer;"
            ></i>
          </h2>
          <div id="socialbladetwitch"></div>
        </div> 
      </div> -->
      <div style="display: flex;">
        <div style="height: 650px; width: 100%; overflow: scroll;">
          <h2 class="text-center" ref="sbodScroll">
            <b>
              Price Range & Ticket Limit
            </b>
          </h2>
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Event Date
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Venue Name
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  TM Link
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  TP Link
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Venue Capacity
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Currency
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Min
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Max
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Ticket Limit
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Sales
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Attractions
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Seat Map
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="!priceRange"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="5"
                >
                  Please wait...
                </td>
              </tr>
              <tr
                v-for="(item, i) in priceRange"
                :key="i"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.date }}
                </td>
                <td
                  class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                  style="width: 20%"
                >
                  {{ item.vname }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <a
                    class="cursor-pointer text-blue-600"
                    :href="item.url"
                    target="_blank"
                    ><img
                      src="https://spaceseats.io/img/ticket.9586a5df.jpeg"
                      alt="img"
                      style="max-width: 30px;"
                  /></a>
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <a
                    class="cursor-pointer text-blue-600"
                    :href="'https://www.tickpick.com/search?q=' + item.ename"
                    target="_blank"
                    ><img
                      src="@/assets/img/landing/tickpick.png"
                      alt="img"
                      style="max-width: 30px;"
                  /></a>
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <div class="text-green-800" :id="'vid' + i">
                    <div
                      @click="getCapacity('vid' + i, item.vname + ' Capacity')"
                    >
                      Fetch
                    </div>
                  </div>
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.currency }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.min }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ item.max }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                >
                  {{ item.tl }}
                </td>
                <td
                  class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                >
                  <p><b>Public:</b></p>
                  <p>&nbsp;Start: {{ item.salesPuStart }}</p>
                  <p><b>Presales:</b></p>
                  <div v-if="item.presales">
                    <div v-for="(p, i) in item.presales" :key="i">
                      <u>&nbsp;{{ p.name }}:</u>
                      <p>&nbsp;&nbsp;Start: {{ p.start }}</p>
                    </div>
                  </div>
                </td>
                <td
                  class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
                >
                  <div v-for="(a, i) in item.attractions" :key="i">
                    <p>&nbsp;{{ a.name }}</p>
                  </div>
                  <div
                    style="cursor: pointer"
                    x-data="{ item.pop: false }"
                    class="text-green-800 relative"
                    type="button"
                    @click="
                      item.pop = !item.pop;
                      getKeywordStatsModal(i, item.attractions);
                    "
                  >
                    Fetch Stats
                    <!-- {{ item.pop ? "Open" : "Closed" }} -->
                  </div>
                  <!-- class="absolute left-1/2 mt-4 w-100 top-20 bg-white rounded-md overflow-hidden shadow-xl z-10" -->

                  <div
                    class="overflow-y-auto overflow-x-hidden mx-auto my-auto w-50 h-50 bg-white fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] shadow-2xl max-h-full bg-gray-800 bg-opacity-50"
                    :id="'keywordsStatsInner' + i"
                    v-show="item.pop"
                    x-transition:enter="transition ease-out duration-100 transform"
                    x-transition:enter-start="opacity-0 scale-95"
                    x-transition:enter-end="opacity-100 scale-100"
                    x-transition:leave="transition ease-in duration-75 transform"
                  >
                    <div
                      class="relative text-center w-100 font-bold px-3 py-10 bg-gray-100 bg-opacity-50 flex justify-center items-center "
                      :id="'stats-modal-header' + i"
                    >
                      <h2 class="text-2xl">Keyword Statistics from Google</h2>

                      <button
                        class="absolute bg-green-300 rounded-full font-semibold py-2 right-2 text-center px-4"
                        @click="item.pop = false"
                        :id="'close-modal' + i"
                      >
                        Close
                      </button>
                    </div>
                    <div
                      class="relative px-10 my-10 "
                      :id="'stats-modal-content' + i"
                    >
                      <p class="text-green-300">
                        Fetching the data may take 5-10 seconds ....
                      </p>
                    </div>
                  </div>
                </td>

                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <img
                    :src="item.seatmap"
                    @click="showImage(item.seatmap)"
                    width="150px"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import * as moment from "moment";
import _ from "lodash";
import LineChart from "@/components/admin/LineChart.vue";
import BarChart from "@/components/admin/BarChart.vue";
import TableHeaderGraph from "@/components/admin/TableHeaderGraph.vue";
import TableHeaderChart from "@/components/admin/TableHeaderChart.vue";
import "viewerjs/dist/viewer.css";
// import Chart from "chart.js";
// import { Line } from 'vue-chartjs'
// import VueCal from 'vue-cal';
// import 'vue-cal/dist/vuecal.css';

export default {
  // components: { LineChart, TableHeaderGraph, TableHeaderChart, VueCal },
  components: { LineChart, BarChart, TableHeaderGraph, TableHeaderChart },
  data() {
    let l = [];
    // var datacompare = [];
    var todos = [];
    var csk = [];
    var priceRange = [];
    var requestOptions1 = {
      method: "GET",
      redirect: "follow",
    };
    // let arrdata = [];
    fetch(
      `${this.$api}/ticket/by-event/${this.$route.params.eventName}/${this.$route.params.city}`,
      requestOptions1
    )
      .then((response1) => response1.text())
      .then((result1) => {
        result1 = JSON.parse(result1);
        // console.log("test1"+result1);
        // console.log(result1.data[0].venuename);

        var requestOptions2 = {
          method: "GET",
          redirect: "follow",
        };

        fetch(
          `https://app.ticketmaster.com/discovery/v2/venues.json?keyword=${result1.data[0].venuename}&apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&city=${this.$route.params.city}`,
          requestOptions2
        )
          .then((response) => response.text())
          .then((result2) => {
            result2 = JSON.parse(result2);

            fetch(
              `https://app.ticketmaster.com/discovery/v2/events.json?apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&venueId=${result2._embedded.venues[0].id}`,
              requestOptions3
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);
                // console.log("test"+result);
                result = result._embedded.events;
                //console.log(result);

                for (let i = 0; i < result.length; i++) {
                  todos.push({
                    description: result[i].name,
                    isComplete: false,
                    // dates:result[i].dates.start.localDate,
                    dates: new Date(
                      result[i].dates.start.localDate
                    ).toLocaleString("en-US", { timeZone: "America/New_York" }),
                    // dates: new Date(result[i].dates.start.localDate).toLocaleString("en-US",{timeZone:"America/LOS_ANGELES"}),
                    color: "red",
                  });
                  l[i] = result[i].dates.start.localDate;
                  //console.log(l)
                  //console.log(i)
                  // console.log(`https://app.ticketmaster.com/discovery/v2/events.json?apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&keyword=${result[i].name}`)

                  // todos.push({
                  //   description:result[i]._embedded.attractions[0].name,
                  //   isComplete: false,
                  //   dates:result[i].dates.start.localDate,
                  //   color: 'green',
                  // })
                }
                // console.log('arrdata'+arrdata)
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) => console.log("error", error));

        var requestOptions3 = {
          method: "GET",
          redirect: "follow",
        };

        fetch(
          `https://app.ticketmaster.com/discovery/v2/events.json?apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW&size=199&keyword=${this.$route.params.eventName}`,
          requestOptions3
        )
          .then((response1) => response1.text())
          .then((result1) => {
            result1 = JSON.parse(result1);

            for (let j = 0; j < result1._embedded.events.length; j++) {
              todos.push({
                description: result1._embedded.events[j].name,
                isComplete: false,
                // dates:result1._embedded.events[j].dates.start.localDate, // dates:new Date(result1._embedded.events[0].dates.start.localDate).toLocaleString("en-US",{timeZone:"America/LOS_ANGELES"}),
                dates: new Date(
                  result1._embedded.events[j].dates.start.localDate
                ).toLocaleString("en-US", { timeZone: "America/New_York" }),
                color: "green",
              });

              if (result1._embedded.events[j].priceRanges) {
                let tlim = "N/A";
                if (result1._embedded.events[j].ticketLimit)
                  tlim = result1._embedded.events[j].ticketLimit.info;
                let smap = "https://spaceseats.io/img/notfound.png";
                if (result1._embedded.events[j].seatmap)
                  smap = result1._embedded.events[j].seatmap.staticUrl;

                let preSales = [];

                let attractionsData = [];

                if (result1._embedded.events[j].sales.presales) {
                  for (
                    let p = 0;
                    p < result1._embedded.events[j].sales.presales.length;
                    p++
                  ) {
                    preSales.push({
                      name: result1._embedded.events[j].sales.presales[p].name,
                      start: new Date(
                        result1._embedded.events[j].sales.presales[
                          p
                        ].startDateTime
                      ).toLocaleString("en-US", {
                        timeZone: "America/New_York",
                      }),
                    });
                  }
                }
                if (result1._embedded.events[j]._embedded.attractions) {
                  for (
                    let a = 0;
                    a <
                    result1._embedded.events[j]._embedded.attractions.length;
                    a++
                  ) {
                    attractionsData.push({
                      name:
                        result1._embedded.events[j]._embedded.attractions[a]
                          .name,
                    });
                  }
                }

                priceRange.push({
                  date: new Date(
                    result1._embedded.events[j].dates.start.localDate
                  ).toLocaleString("en-US", { timeZone: "America/New_York" }),
                  vname: result1._embedded.events[j]._embedded.venues[0].name,
                  ename: result1._embedded.events[j].name,
                  url: result1._embedded.events[j].url,
                  currency: result1._embedded.events[j].priceRanges[0].currency,
                  min: result1._embedded.events[j].priceRanges[0].min,
                  max: result1._embedded.events[j].priceRanges[0].max,
                  tl: tlim,
                  salesPuStart: new Date(
                    result1._embedded.events[j].sales.public.startDateTime
                  ).toLocaleString("en-US", { timeZone: "America/New_York" }),
                  presales: preSales,
                  attractions: attractionsData,
                  pop: false,
                  seatmap: smap,
                });
                this.locations.push({
                  lat: parseFloat(
                    result1._embedded.events[j]._embedded.venues[0].location
                      .latitude
                  ),
                  lng: parseFloat(
                    result1._embedded.events[j]._embedded.venues[0].location
                      .longitude
                  ),
                  label:
                    result1._embedded.events[j]._embedded.venues[0].city.name,
                });
              }
            }
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));

    return {
      incId: todos.length,
      todos,
      csk,
      aname: "",
      priceRange,
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locations: [],
      showComp: false,
      showCompTrend7d: false,
      showCompTrend1y: false,
      showCompTrend5y: false,
      showCompIoverTime: false,
      currentLocation: null,
    };
  },

  computed: {
    attributes() {
      return [
        // Attributes for todos
        ...this.todos.map((todo) => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: todo.isComplete ? "opacity-75" : "",
          },
          popover: {
            label: todo.description,
          },
          customData: todo,
        })),
      ];
    },
    // datas(){
    //   return "{ month: 1, year: 2012 }"
    // }
  },

  created() {
    this.getTixs("AvailableTixs", "Available Tixs", "#8FEAC7");
    // this.socialblade()
    // this.socialbladetwitter()
    // this.venuename()
    // this.socialbladettiktok1()
    // this.socialbladetwitch()
    // this.Billboard200()
    // this.youtube();
    // this.spotify()
    // this.mytest()
    // this.chatgpt()
    // this.googleresultapi()
    // this.googleresultapidata()
    // this.Billboardhot100()
    // this.Artist100()
    // this.data1()
  },
  methods: {
    formatDate(ts) {
      const date = new Date(ts);
      return date.toDateString(); // or use toLocaleDateString() for a localized format
    },
    setPlace(loc) {
      this.currentLocation = loc;
    },
    onLoad() {},
    lower(val) {
      return val.toLowerCase();
    },
    getCST(ts, format) {
      return moment(moment(ts).utcOffset("-05:00")).format(format);
    },
    getKeywordStatsModal(k, keywords_array) {
      // console.log(keywords_array[0].name);
      //console.log(keywords_array.length);

      for (var i = 0; i < keywords_array.length; i++) {
        this.csk.push(keywords_array[i].name);
      }
      console.log(this.csk);

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `https://varilytics.com/spaceseat/gads/get_ads.php?keywords=${this.csk}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          document.getElementById("stats-modal-content" + k).innerHTML = result;
          // document.getElementById("stats-modal-close" + k).className =
          // "visible text-center bg-gray-100 bg-opacity-50 sticky bottom-0";
        });
    },
    showImage(url) {
      this.$viewerApi({
        images: [url],
      });
    },
    socialblade(w) {
      this.$refs.sbyScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/youtube/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            document.getElementById("socialblade").innerHTML = "Loading...";

            console.log(w);
            fetch(
              `https://matrix.sbapis.com/b/youtube/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);
                var html1 =
                  "<table><thead><tr><td style='padding-left:100px'>Date</td><td style='padding-left:100px'>Subs</td><td style='padding-left:100px'>Views</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  console.log(w);
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:100px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:100px'>" +
                    result.data.daily[i].subs +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:100px'>" +
                    result.data.daily[i].views +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById("socialblade").innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialblade").innerHTML =
                  "Try again later.";
              });
          } else {
            // console.log(result)
            var html2 =
              "<table><thead><tr><td style='padding-left:100px'>Date</td><td style='padding-left:100px'>Subs</td><td style='padding-left:100px'>Views</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:100px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:100px'>" +
                result.data.daily[i].subs +
                "</td>";
              html2 +=
                "<td style='padding-left:100px'>" +
                result.data.daily[i].views +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialblade").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialblade").innerHTML = "Try again later.";
        });
    },
    socialbladetwitter(w) {
      document.getElementById("socialbladetwitter").innerHTML = "Loading...";

      this.$refs.sbtScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/twitter/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            fetch(
              `https://matrix.sbapis.com/b/twitter/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);

                var html1 = "";

                html1 += "<div>";

                html1 += "<h1> Statistics Total </h1>";

                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.statistics.total.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.statistics.total.following +
                  "</p>";
                html1 +=
                  "<p><b> tweets </b> : " +
                  result.data.statistics.total.tweets +
                  "</p>";

                html1 += "</div>";

                html1 += "<div>";

                html1 += "<h1>Ranks</h1>";

                html1 +=
                  "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.ranks.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.ranks.following +
                  "</p>";
                html1 +=
                  "<p><b> tweets </b> : " + result.data.ranks.tweets + "</p>";

                html1 += "</div>";

                html1 +=
                  "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Tweets</td><td style='padding-left:50px'>Favorites</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].followers +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].following +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].tweets +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].favorites +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById("socialbladetwitter").innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialbladetwitter").innerHTML =
                  "Try again later.";
              });
          } else {
            var html2 = "";

            html2 += "<div>";

            html2 += "<h1> Statistics Total </h1>";

            html2 +=
              "<p><b> followers </b> : " +
              result.data.statistics.total.followers +
              "</p>";
            html2 +=
              "<p><b> following </b> : " +
              result.data.statistics.total.following +
              "</p>";
            html2 +=
              "<p><b> tweets </b> : " +
              result.data.statistics.total.tweets +
              "</p>";

            html2 += "</div>";

            html2 += "<div>";

            html2 += "<h1>Ranks</h1>";

            html2 +=
              "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
            html2 +=
              "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
            html2 +=
              "<p><b> following </b> : " + result.data.ranks.following + "</p>";
            html2 +=
              "<p><b> tweets </b> : " + result.data.ranks.tweets + "</p>";

            html2 += "</div>";

            // console.log(result)
            html2 +=
              "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Tweets</td><td style='padding-left:50px'>Favorites</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].followers +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].following +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].tweets +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].favorites +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialbladetwitter").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialbladetwitter").innerHTML =
            "Try again later.";
        });
    },
    socialbladetwitch(w) {
      document.getElementById("socialbladetwitch").innerHTML = "Loading...";

      this.$refs.sbtwScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/twitch/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            fetch(
              `https://matrix.sbapis.com/b/twitch/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);

                var html1 = "";

                html1 += "<div>";

                html1 += "<h1> Statistics Total </h1>";

                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.statistics.total.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.statistics.total.following +
                  "</p>";
                html1 +=
                  "<p><b> tweets </b> : " +
                  result.data.statistics.total.tweets +
                  "</p>";

                html1 += "</div>";

                html1 += "<div>";

                html1 += "<h1>Ranks</h1>";

                html1 +=
                  "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.ranks.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.ranks.following +
                  "</p>";
                html1 +=
                  "<p><b> tweets </b> : " + result.data.ranks.tweets + "</p>";

                html1 += "</div>";

                html1 +=
                  "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Tweets</td><td style='padding-left:50px'>Favorites</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].followers +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].following +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].tweets +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].favorites +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById("socialbladetwitch").innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialbladetwitch").innerHTML =
                  "Try again later.";
              });
          } else {
            var html2 = "";

            html2 += "<div>";

            html2 += "<h1> Statistics Total </h1>";

            html2 +=
              "<p><b> followers </b> : " +
              result.data.statistics.total.followers +
              "</p>";
            html2 +=
              "<p><b> following </b> : " +
              result.data.statistics.total.following +
              "</p>";
            html2 +=
              "<p><b> tweets </b> : " +
              result.data.statistics.total.tweets +
              "</p>";

            html2 += "</div>";

            html2 += "<div>";

            html2 += "<h1>Ranks</h1>";

            html2 +=
              "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
            html2 +=
              "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
            html2 +=
              "<p><b> following </b> : " + result.data.ranks.following + "</p>";
            html2 +=
              "<p><b> tweets </b> : " + result.data.ranks.tweets + "</p>";

            html2 += "</div>";

            html2 +=
              "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Tweets</td><td style='padding-left:50px'>Favorites</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].followers +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].following +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].tweets +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].favorites +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialbladetwitch").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialbladetwitch").innerHTML =
            "Try again later.";
        });
    },
    socialbladeinstagram(w) {
      document.getElementById("socialbladeinstagram").innerHTML = "Loading...";

      this.$refs.sbinScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/instagram/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            fetch(
              `https://matrix.sbapis.com/b/instagram/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);

                var html1 = "";

                html1 += "<div>";

                html1 += "<h1> Statistics Total </h1>";

                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.statistics.total.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.statistics.total.following +
                  "</p>";
                html1 +=
                  "<p><b> media </b> : " +
                  result.data.statistics.total.media +
                  "</p>";
                html1 +=
                  "<p><b> engagement_rate </b> : " +
                  result.data.statistics.total.engagement_rate +
                  "</p>";

                html1 += "</div>";

                html1 += "<div>";

                html1 += "<h1>Ranks</h1>";

                html1 +=
                  "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.ranks.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.ranks.following +
                  "</p>";
                html1 +=
                  "<p><b> media </b> : " + result.data.ranks.media + "</p>";
                html1 +=
                  "<p><b> engagement_rate </b> : " +
                  result.data.ranks.engagement_rate +
                  "</p>";

                html1 += "</div>";

                html1 +=
                  "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Media</td><td style='padding-left:50px'>Avg. Likes</td><td style='padding-left:50px'>Avg. Comments</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].followers +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].following +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].media +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].avg_likes +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].avg_comments +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById(
                  "socialbladeinstagram"
                ).innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialbladeinstagram").innerHTML =
                  "Try again later.";
              });
          } else {
            var html2 = "";

            html2 += "<div>";

            html2 += "<h1> Statistics Total </h1>";

            html2 +=
              "<p><b> followers </b> : " +
              result.data.statistics.total.followers +
              "</p>";
            html2 +=
              "<p><b> following </b> : " +
              result.data.statistics.total.following +
              "</p>";
            html2 +=
              "<p><b> media </b> : " +
              result.data.statistics.total.media +
              "</p>";
            html2 +=
              "<p><b> engagement_rate </b> : " +
              result.data.statistics.total.engagement_rate +
              "</p>";

            html2 += "</div>";

            html2 += "<div>";

            html2 += "<h1>Ranks</h1>";

            html2 +=
              "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
            html2 +=
              "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
            html2 +=
              "<p><b> following </b> : " + result.data.ranks.following + "</p>";
            html2 += "<p><b> media </b> : " + result.data.ranks.media + "</p>";
            html2 +=
              "<p><b> engagement_rate </b> : " +
              result.data.ranks.engagement_rate +
              "</p>";

            html2 += "</div>";

            html2 +=
              "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Media</td><td style='padding-left:50px'>Avg. Likes</td><td style='padding-left:50px'>Avg. Comments</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].followers +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].following +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].media +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].avg_likes +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].avg_comments +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialbladeinstagram").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialbladeinstagram").innerHTML =
            "Try again later.";
        });
    },
    socialbladettiktok(w) {
      document.getElementById("socialbladettiktok").innerHTML = "Loading...";

      this.$refs.sbtkScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/tiktok/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            fetch(
              `https://matrix.sbapis.com/b/tiktok/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);

                var html1 = "";

                html1 += "<div>";

                html1 += "<h1> Statistics Total </h1>";

                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.statistics.total.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.statistics.total.following +
                  "</p>";
                html1 +=
                  "<p><b> uploads </b> : " +
                  result.data.statistics.total.uploads +
                  "</p>";
                html1 +=
                  "<p><b> likes </b> : " +
                  result.data.statistics.total.likes +
                  "</p>";

                html1 += "</div>";

                html1 += "<div>";

                html1 += "<h1>Ranks</h1>";

                html1 +=
                  "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
                html1 +=
                  "<p><b> followers </b> : " +
                  result.data.ranks.followers +
                  "</p>";
                html1 +=
                  "<p><b> following </b> : " +
                  result.data.ranks.following +
                  "</p>";
                html1 +=
                  "<p><b> uploads </b> : " + result.data.ranks.uploads + "</p>";
                html1 +=
                  "<p><b> likes </b> : " + result.data.ranks.likes + "</p>";

                html1 += "</div>";

                html1 +=
                  "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Likes</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].followers +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].following +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].likes +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].uploads +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById("socialbladettiktok").innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialbladettiktok").innerHTML =
                  "Try again later.";
              });
          } else {
            var html2 = "";

            html2 += "<div>";

            html2 += "<h1> Statistics Total </h1>";

            html2 +=
              "<p><b> followers </b> : " +
              result.data.statistics.total.followers +
              "</p>";
            html2 +=
              "<p><b> following </b> : " +
              result.data.statistics.total.following +
              "</p>";
            html2 +=
              "<p><b> uploads </b> : " +
              result.data.statistics.total.uploads +
              "</p>";
            html2 +=
              "<p><b> likes </b> : " +
              result.data.statistics.total.likes +
              "</p>";

            html2 += "</div>";

            html2 += "<div>";

            html2 += "<h1>Ranks</h1>";

            html2 +=
              "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
            html2 +=
              "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
            html2 +=
              "<p><b> following </b> : " + result.data.ranks.following + "</p>";
            html2 +=
              "<p><b> uploads </b> : " + result.data.ranks.uploads + "</p>";
            html2 += "<p><b> likes </b> : " + result.data.ranks.likes + "</p>";

            html2 += "</div>";

            html2 +=
              "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Likes</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].followers +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].following +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].likes +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].uploads +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialbladettiktok").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialbladettiktok").innerHTML =
            "Try again later.";
        });
    },
    socialbladefacebook(w) {
      document.getElementById("socialbladefacebook").innerHTML = "Loading...";

      this.$refs.sbfbScroll?.scrollIntoView({ behavior: "smooth" });

      w = w.replace(":", "");
      w = w.replace("-", "");
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://matrix.sbapis.com/b/facebook/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          if (result.status.status == 404) {
            w = w.trim();
            w = w.split(" ");
            w = w[0];

            fetch(
              `https://matrix.sbapis.com/b/facebook/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => {
                result = JSON.parse(result);

                var html1 = "";

                html1 += "<div>";

                html1 += "<h1> Statistics Total </h1>";

                html1 +=
                  "<p><b> likes </b> : " +
                  result.data.statistics.total.likes +
                  "</p>";
                html1 +=
                  "<p><b> talking about </b> : " +
                  result.data.statistics.total.talking_about +
                  "</p>";

                html1 += "</div>";

                html1 += "<div>";

                html1 += "<h1>Ranks</h1>";

                html1 +=
                  "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
                html1 +=
                  "<p><b> likes </b> : " + result.data.ranks.likes + "</p>";
                html1 +=
                  "<p><b> talking about </b> : " +
                  result.data.ranks.talking_about +
                  "</p>";

                html1 += "</div>";

                html1 +=
                  "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Likes</td><td style='padding-left:50px'>Talking About</td></tr></thead><tbody>";
                for (let i = 0; i < result.data.daily.length; i++) {
                  html1 += "<tr>";

                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].date +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].likes +
                    "</td>";
                  html1 +=
                    "<td style='padding-left:50px'>" +
                    result.data.daily[i].talking_about +
                    "</td>";

                  html1 += "<tr>";
                }
                html1 += "</tbody></table>";
                document.getElementById(
                  "socialbladefacebook"
                ).innerHTML = html1;
              })
              .catch((error) => {
                console.log("error", error);
                document.getElementById("socialbladefacebook").innerHTML =
                  "Try again later.";
              });
          } else {
            var html2 = "";

            html2 += "<div>";

            html2 += "<h1> Statistics Total </h1>";

            html2 +=
              "<p><b> likes </b> : " +
              result.data.statistics.total.likes +
              "</p>";
            html2 +=
              "<p><b> talking about </b> : " +
              result.data.statistics.total.talking_about +
              "</p>";

            html2 += "</div>";

            html2 += "<div>";

            html2 += "<h1>Ranks</h1>";

            html2 +=
              "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
            html2 += "<p><b> likes </b> : " + result.data.ranks.likes + "</p>";
            html2 +=
              "<p><b> talking about </b> : " +
              result.data.ranks.talking_about +
              "</p>";

            html2 += "</div>";

            html2 +=
              "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Likes</td><td style='padding-left:50px'>Talking About</td></tr></thead><tbody>";
            for (let i = 0; i < result.data.daily.length; i++) {
              html2 += "<tr>";

              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].date +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].likes +
                "</td>";
              html2 +=
                "<td style='padding-left:50px'>" +
                result.data.daily[i].talking_about +
                "</td>";

              html2 += "<tr>";
            }
            html2 += "</tbody></table>";
            document.getElementById("socialbladefacebook").innerHTML = html2;
          }
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("socialbladefacebook").innerHTML =
            "Try again later.";
        });
    },
    // socialbladetrovo(w) {
    //   document.getElementById("socialbladetrovo").innerHTML = "Loading...";

    //   this.$refs.sbtrScroll?.scrollIntoView({ behavior: "smooth" });

    //   w = w.replace(":", "");
    //   w = w.replace("-", "");
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow"
    //   };

    //   fetch(
    //     `https://matrix.sbapis.com/b/trovo/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //     requestOptions
    //   )
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result);

    //       if (result.status.status == 404) {
    //         w = w.trim();
    //         w = w.split(" ");
    //         w = w[0];

    //         fetch(
    //           `https://matrix.sbapis.com/b/trovo/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //           requestOptions
    //         )
    //           .then(response => response.text())
    //           .then(result => {
    //             result = JSON.parse(result);

    //             var html1 = "";

    //             html1 += "<div>";

    //             html1 += "<h1> Statistics Total </h1>";

    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.statistics.total.followers +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 += "<div>";

    //             html1 += "<h1>Ranks</h1>";

    //             html1 +=
    //               "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.ranks.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> audience </b> : " +
    //               result.data.ranks.audience +
    //               "</p>";
    //             html1 +=
    //               "<p><b> language </b> : " +
    //               result.data.ranks.language +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 +=
    //               "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td></tr></thead><tbody>";
    //             for (let i = 0; i < result.data.daily.length; i++) {
    //               html1 += "<tr>";

    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].date +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].followers +
    //                 "</td>";

    //               html1 += "<tr>";
    //             }
    //             html1 += "</tbody></table>";
    //             document.getElementById("socialbladetrovo").innerHTML = html1;
    //           })
    //           .catch(error => {
    //             console.log("error", error);
    //             document.getElementById("socialbladetrovo").innerHTML =
    //               "Try again later.";
    //           });
    //       } else {
    //         var html2 = "";

    //         html2 += "<div>";

    //         html2 += "<h1> Statistics Total </h1>";

    //         html2 +=
    //           "<p><b> followers </b> : " +
    //           result.data.statistics.total.followers +
    //           "</p>";

    //         html2 += "</div>";

    //         html2 += "<div>";

    //         html2 += "<h1>Ranks</h1>";

    //         html2 +=
    //           "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //         html2 +=
    //           "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
    //         html2 +=
    //           "<p><b> audience </b> : " + result.data.ranks.audience + "</p>";
    //         html2 +=
    //           "<p><b> language </b> : " + result.data.ranks.language + "</p>";

    //         html2 += "</div>";

    //         html2 +=
    //           "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td></tr></thead><tbody>";
    //         for (let i = 0; i < result.data.daily.length; i++) {
    //           html2 += "<tr>";

    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].date +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].followers +
    //             "</td>";

    //           html2 += "<tr>";
    //         }
    //         html2 += "</tbody></table>";
    //         document.getElementById("socialbladetrovo").innerHTML = html2;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error", error);
    //       document.getElementById("socialbladetrovo").innerHTML =
    //         "Try again later.";
    //     });
    // },
    // socialbladestoryfire(w) {
    //   document.getElementById("socialbladestoryfire").innerHTML = "Loading...";

    //   this.$refs.sbsfScroll?.scrollIntoView({ behavior: "smooth" });

    //   w = w.replace(":", "");
    //   w = w.replace("-", "");
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow"
    //   };

    //   fetch(
    //     `https://matrix.sbapis.com/b/storyfire/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //     requestOptions
    //   )
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result);

    //       if (result.status.status == 404) {
    //         w = w.trim();
    //         w = w.split(" ");
    //         w = w[0];

    //         fetch(
    //           `https://matrix.sbapis.com/b/storyfire/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //           requestOptions
    //         )
    //           .then(response => response.text())
    //           .then(result => {
    //             result = JSON.parse(result);

    //             var html1 = "";

    //             html1 += "<div>";

    //             html1 += "<h1> Statistics Total </h1>";

    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.statistics.total.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " +
    //               result.data.statistics.total.views +
    //               "</p>";
    //             html1 +=
    //               "<p><b> videos </b> : " +
    //               result.data.statistics.total.videos +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 += "<div>";

    //             html1 += "<h1>Ranks</h1>";

    //             html1 +=
    //               "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.ranks.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " + result.data.ranks.views + "</p>";
    //             html1 +=
    //               "<p><b> videos </b> : " + result.data.ranks.videos + "</p>";

    //             html1 += "</div>";

    //             html1 +=
    //               "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Videos</td></tr></thead><tbody>";
    //             for (let i = 0; i < result.data.daily.length; i++) {
    //               html1 += "<tr>";

    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].date +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].followers +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].views +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].videos +
    //                 "</td>";

    //               html1 += "<tr>";
    //             }
    //             html1 += "</tbody></table>";
    //             document.getElementById(
    //               "socialbladestoryfire"
    //             ).innerHTML = html1;
    //           })
    //           .catch(error => {
    //             console.log("error", error);
    //             document.getElementById("socialbladestoryfire").innerHTML =
    //               "Try again later.";
    //           });
    //       } else {
    //         var html2 = "";

    //         html2 += "<div>";

    //         html2 += "<h1> Statistics Total </h1>";

    //         html2 +=
    //           "<p><b> followers </b> : " +
    //           result.data.statistics.total.followers +
    //           "</p>";
    //         html2 +=
    //           "<p><b> views </b> : " +
    //           result.data.statistics.total.views +
    //           "</p>";
    //         html2 +=
    //           "<p><b> videos </b> : " +
    //           result.data.statistics.total.videos +
    //           "</p>";

    //         html2 += "</div>";

    //         html2 += "<div>";

    //         html2 += "<h1>Ranks</h1>";

    //         html2 +=
    //           "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //         html2 +=
    //           "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
    //         html2 += "<p><b> views </b> : " + result.data.ranks.views + "</p>";
    //         html2 +=
    //           "<p><b> videos </b> : " + result.data.ranks.videos + "</p>";

    //         html2 += "</div>";

    //         html2 +=
    //           "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Videos</td></tr></thead><tbody>";
    //         for (let i = 0; i < result.data.daily.length; i++) {
    //           html2 += "<tr>";

    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].date +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].followers +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].views +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].videos +
    //             "</td>";

    //           html2 += "<tr>";
    //         }
    //         html2 += "</tbody></table>";
    //         document.getElementById("socialbladestoryfire").innerHTML = html2;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error", error);
    //       document.getElementById("socialbladestoryfire").innerHTML =
    //         "Try again later.";
    //     });
    // },
    // socialbladedlive(w) {
    //   document.getElementById("socialbladedlive").innerHTML = "Loading...";

    //   this.$refs.sbdlScroll?.scrollIntoView({ behavior: "smooth" });

    //   w = w.replace(":", "");
    //   w = w.replace("-", "");
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow"
    //   };

    //   fetch(
    //     `https://matrix.sbapis.com/b/dlive/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //     requestOptions
    //   )
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result);

    //       if (result.status.status == 404) {
    //         w = w.trim();
    //         w = w.split(" ");
    //         w = w[0];

    //         fetch(
    //           `https://matrix.sbapis.com/b/dlive/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //           requestOptions
    //         )
    //           .then(response => response.text())
    //           .then(result => {
    //             result = JSON.parse(result);

    //             var html1 = "";

    //             html1 += "<div>";

    //             html1 += "<h1> Statistics Total </h1>";

    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.statistics.total.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> following </b> : " +
    //               result.data.statistics.total.following +
    //               "</p>";
    //             html1 +=
    //               "<p><b> earnings </b> : " +
    //               result.data.statistics.total.earnings +
    //               "</p>";
    //             html1 +=
    //               "<p><b> videos </b> : " +
    //               result.data.statistics.total.videos +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 += "<div>";

    //             html1 += "<h1>Ranks</h1>";

    //             html1 +=
    //               "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.ranks.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> following </b> : " +
    //               result.data.ranks.following +
    //               "</p>";
    //             html1 +=
    //               "<p><b> videos </b> : " + result.data.ranks.videos + "</p>";
    //             html1 +=
    //               "<p><b> earnings </b> : " +
    //               result.data.ranks.earnings +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 +=
    //               "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Videos</td><td style='padding-left:50px'>Earnings</td></tr></thead><tbody>";
    //             for (let i = 0; i < result.data.daily.length; i++) {
    //               html1 += "<tr>";

    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].date +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].followers +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].following +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].videos +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].earnings +
    //                 "</td>";

    //               html1 += "<tr>";
    //             }
    //             html1 += "</tbody></table>";
    //             document.getElementById("socialbladedlive").innerHTML = html1;
    //           })
    //           .catch(error => {
    //             console.log("error", error);
    //             document.getElementById("socialbladedlive").innerHTML =
    //               "Try again later.";
    //           });
    //       } else {
    //         var html2 = "";

    //         html2 += "<div>";

    //         html2 += "<h1> Statistics Total </h1>";

    //         html2 +=
    //           "<p><b> followers </b> : " +
    //           result.data.statistics.total.followers +
    //           "</p>";
    //         html2 +=
    //           "<p><b> following </b> : " +
    //           result.data.statistics.total.following +
    //           "</p>";
    //         html2 +=
    //           "<p><b> earnings </b> : " +
    //           result.data.statistics.total.earnings +
    //           "</p>";
    //         html2 +=
    //           "<p><b> videos </b> : " +
    //           result.data.statistics.total.videos +
    //           "</p>";

    //         html2 += "</div>";

    //         html2 += "<div>";

    //         html2 += "<h1>Ranks</h1>";

    //         html2 +=
    //           "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //         html2 +=
    //           "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
    //         html2 +=
    //           "<p><b> following </b> : " + result.data.ranks.following + "</p>";
    //         html2 +=
    //           "<p><b> videos </b> : " + result.data.ranks.videos + "</p>";
    //         html2 +=
    //           "<p><b> earnings </b> : " + result.data.ranks.earnings + "</p>";

    //         html2 += "</div>";

    //         html2 +=
    //           "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Videos</td><td style='padding-left:50px'>Earnings</td></tr></thead><tbody>";
    //         for (let i = 0; i < result.data.daily.length; i++) {
    //           html2 += "<tr>";

    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].date +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].followers +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].following +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].videos +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].earnings +
    //             "</td>";

    //           html2 += "<tr>";
    //         }
    //         html2 += "</tbody></table>";
    //         document.getElementById("socialbladedlive").innerHTML = html2;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error", error);
    //       document.getElementById("socialbladedlive").innerHTML =
    //         "Try again later.";
    //     });
    // },
    // socialbladedailymotion(w) {
    //   document.getElementById("socialbladedailymotion").innerHTML =
    //     "Loading...";

    //   this.$refs.sbdmScroll?.scrollIntoView({ behavior: "smooth" });

    //   w = w.replace(":", "");
    //   w = w.replace("-", "");
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow"
    //   };

    //   fetch(
    //     `https://matrix.sbapis.com/b/dailymotion/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //     requestOptions
    //   )
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result);

    //       if (result.status.status == 404) {
    //         w = w.trim();
    //         w = w.split(" ");
    //         w = w[0];

    //         fetch(
    //           `https://matrix.sbapis.com/b/dailymotion/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //           requestOptions
    //         )
    //           .then(response => response.text())
    //           .then(result => {
    //             result = JSON.parse(result);

    //             var html1 = "";

    //             html1 += "<div>";

    //             html1 += "<h1> Statistics Total </h1>";

    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.statistics.total.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> following </b> : " +
    //               result.data.statistics.total.following +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " +
    //               result.data.statistics.total.views +
    //               "</p>";
    //             html1 +=
    //               "<p><b> uploads </b> : " +
    //               result.data.statistics.total.uploads +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 += "<div>";

    //             html1 += "<h1>Ranks</h1>";

    //             html1 +=
    //               "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //             html1 +=
    //               "<p><b> followers </b> : " +
    //               result.data.ranks.followers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " + result.data.ranks.views + "</p>";
    //             html1 +=
    //               "<p><b> country </b> : " + result.data.ranks.country + "</p>";
    //             html1 +=
    //               "<p><b> channel_type </b> : " +
    //               result.data.ranks.channel_type +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 +=
    //               "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
    //             for (let i = 0; i < result.data.daily.length; i++) {
    //               html1 += "<tr>";

    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].date +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].followers +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].following +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].views +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].uploads +
    //                 "</td>";

    //               html1 += "<tr>";
    //             }
    //             html1 += "</tbody></table>";
    //             document.getElementById(
    //               "socialbladedailymotion"
    //             ).innerHTML = html1;
    //           })
    //           .catch(error => {
    //             console.log("error", error);
    //             document.getElementById("socialbladedailymotion").innerHTML =
    //               "Try again later.";
    //           });
    //       } else {
    //         var html2 = "";

    //         html2 += "<div>";

    //         html2 += "<h1> Statistics Total </h1>";

    //         html2 +=
    //           "<p><b> followers </b> : " +
    //           result.data.statistics.total.followers +
    //           "</p>";
    //         html2 +=
    //           "<p><b> following </b> : " +
    //           result.data.statistics.total.following +
    //           "</p>";
    //         html2 +=
    //           "<p><b> views </b> : " +
    //           result.data.statistics.total.views +
    //           "</p>";
    //         html2 +=
    //           "<p><b> uploads </b> : " +
    //           result.data.statistics.total.uploads +
    //           "</p>";

    //         html2 += "</div>";

    //         html2 += "<div>";

    //         html2 += "<h1>Ranks</h1>";

    //         html2 +=
    //           "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //         html2 +=
    //           "<p><b> followers </b> : " + result.data.ranks.followers + "</p>";
    //         html2 +=
    //           "<p><b> following </b> : " + result.data.ranks.following + "</p>";
    //         html2 +=
    //           "<p><b> videos </b> : " + result.data.ranks.videos + "</p>";
    //         html2 +=
    //           "<p><b> earnings </b> : " + result.data.ranks.earnings + "</p>";

    //         html2 += "</div>";

    //         html2 +=
    //           "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Followers</td><td style='padding-left:50px'>Following</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
    //         for (let i = 0; i < result.data.daily.length; i++) {
    //           html2 += "<tr>";

    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].date +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].followers +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].following +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].views +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].uploads +
    //             "</td>";

    //           html2 += "<tr>";
    //         }
    //         html2 += "</tbody></table>";
    //         document.getElementById("socialbladedailymotion").innerHTML = html2;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error", error);
    //       document.getElementById("socialbladedailymotion").innerHTML =
    //         "Try again later.";
    //     });
    // },
    // socialbladeodysee(w) {
    //   document.getElementById("socialbladeodysee").innerHTML = "Loading...";

    //   this.$refs.sbodScroll?.scrollIntoView({ behavior: "smooth" });

    //   w = w.replace(":", "");
    //   w = w.replace("-", "");
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow"
    //   };

    //   fetch(
    //     `https://matrix.sbapis.com/b/odysee/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //     requestOptions
    //   )
    //     .then(response => response.text())
    //     .then(result => {
    //       result = JSON.parse(result);

    //       if (result.status.status == 404) {
    //         w = w.trim();
    //         w = w.split(" ");
    //         w = w[0];

    //         fetch(
    //           `https://matrix.sbapis.com/b/odysee/statistics?query=${w}&clientid=cli_f5e2b32eac909e9f1ba9eb51&token=03ff5a4997809fb925f40a816d4270cf0e2b2bb8fee51b574798110e5f208567ea3aba5edb22c6e90a6dd5835e0cc890aa4e62788ae752e75b1e8e14b5ca3c32`,
    //           requestOptions
    //         )
    //           .then(response => response.text())
    //           .then(result => {
    //             result = JSON.parse(result);

    //             var html1 = "";

    //             html1 += "<div>";

    //             html1 += "<h1> Statistics Total </h1>";

    //             html1 +=
    //               "<p><b> subscribers </b> : " +
    //               result.data.statistics.total.subscribers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " +
    //               result.data.statistics.total.views +
    //               "</p>";
    //             html1 +=
    //               "<p><b> uploads </b> : " +
    //               result.data.statistics.total.uploads +
    //               "</p>";

    //             html1 += "</div>";

    //             html1 += "<div>";

    //             html1 += "<h1>Ranks</h1>";

    //             html1 +=
    //               "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //             html1 +=
    //               "<p><b> subscribers </b> : " +
    //               result.data.ranks.subscribers +
    //               "</p>";
    //             html1 +=
    //               "<p><b> views </b> : " + result.data.ranks.views + "</p>";
    //             html1 +=
    //               "<p><b> uploads </b> : " + result.data.ranks.uploads + "</p>";

    //             html1 += "</div>";

    //             html1 +=
    //               "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Subscribers</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
    //             for (let i = 0; i < result.data.daily.length; i++) {
    //               html1 += "<tr>";

    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].date +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].subscribers +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].views +
    //                 "</td>";
    //               html1 +=
    //                 "<td style='padding-left:50px'>" +
    //                 result.data.daily[i].uploads +
    //                 "</td>";

    //               html1 += "<tr>";
    //             }
    //             html1 += "</tbody></table>";
    //             document.getElementById("socialbladeodysee").innerHTML = html1;
    //           })
    //           .catch(error => {
    //             console.log("error", error);
    //             document.getElementById("socialbladeodysee").innerHTML =
    //               "Try again later.";
    //           });
    //       } else {
    //         var html2 = "";

    //         html2 += "<div>";

    //         html2 += "<h1> Statistics Total </h1>";

    //         html2 +=
    //           "<p><b> subscribers </b> : " +
    //           result.data.statistics.total.subscribers +
    //           "</p>";
    //         html2 +=
    //           "<p><b> views </b> : " +
    //           result.data.statistics.total.views +
    //           "</p>";
    //         html2 +=
    //           "<p><b> uploads </b> : " +
    //           result.data.statistics.total.uploads +
    //           "</p>";

    //         html2 += "</div>";

    //         html2 += "<div>";

    //         html2 += "<h1>Ranks</h1>";

    //         html2 +=
    //           "<p><b> sbrank </b> : " + result.data.ranks.sbrank + "</p>";
    //         html2 +=
    //           "<p><b> subscribers </b> : " +
    //           result.data.ranks.subscribers +
    //           "</p>";
    //         html2 += "<p><b> views </b> : " + result.data.ranks.views + "</p>";
    //         html2 +=
    //           "<p><b> uploads </b> : " + result.data.ranks.uploads + "</p>";

    //         html2 += "</div>";

    //         html2 +=
    //           "<table><thead><tr><td style='padding-left:50px'>Date</td><td style='padding-left:50px'>Subscribers</td><td style='padding-left:50px'>Views</td><td style='padding-left:50px'>Uploads</td></tr></thead><tbody>";
    //         for (let i = 0; i < result.data.daily.length; i++) {
    //           html2 += "<tr>";

    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].date +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].subscribers +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].views +
    //             "</td>";
    //           html2 +=
    //             "<td style='padding-left:50px'>" +
    //             result.data.daily[i].uploads +
    //             "</td>";

    //           html2 += "<tr>";
    //         }
    //         html2 += "</tbody></table>";
    //         document.getElementById("socialbladeodysee").innerHTML = html2;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error", error);
    //       document.getElementById("socialbladeodysee").innerHTML =
    //         "Try again later.";
    //     });
    // },
    youtubeMusic() {
      document.getElementById("youtubemusic").innerHTML = "Loading...";
      let event = this.$route.params.eventName;

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://spaceseats.io/api/youtubemusic?fname=${event}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          var html =
            "<html>" +
            result.data.replace(/(?:\r\n|\r|\n)/g, "<br />") +
            "</html>";
          // console.log(html)
          document.getElementById("youtubemusic").innerHTML = html.replace(
            /(?:\r\t)/g,
            " "
          );
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("youtubemusic").innerHTML =
            "Try again later.";
        });
    },
    getTixs(type, label, backgroundColor) {
      this.datacollection = [];
      this.isGraphShow = true;
      this.loading = "";
      this.$axios
        .get(
          `${this.$api}/ticket/by-event/${this.$route.params.eventName}/${this.$route.params.city}`
        )
        .then((res) => {
          console.log("tix");
          console.log(res);
          if (res.data.success) {
            const data = res.data.data;
            if (data.length === 0) {
              this.loading = "No data found";
              return;
            }
            if (!res.data.success) {
              this.loading = "No data found";
              return;
            }
            if (data.length > 0) {
              this.dates = data.map((val) =>
                this.getCST(val.createdat, "D MMM")
              );
              // this.dates = data
              //   .filter(
              //     v =>
              //       this.getCST(v.createdat, "HH") >= 11 &&
              //       this.getCST(v.createdat, "HH") <= 12
              //   )
              //   .map(val => this.getCST(val.CreatedAt, "D MMM"));
              //console.log(this.dates);
              // this.noOfTix = data
              //   .filter(
              //     v =>
              //       this.getCST(v.createdat, "HH") >= 11 &&
              //       this.getCST(v.createdat, "HH") <= 12
              //   )
              //   .map(val =>
              //     parseFloat(
              //       val[type.toLowerCase()].toString().replace(/\$/g, "")
              //     )
              //   );
              this.noOfTix = data.map((val) =>
                parseFloat(
                  val[type.toLowerCase()].toString().replace(/\$/g, "")
                )
              );
               console.log("getTixs"+this.noOfTix);
               console.log(this.noOfTix);
              this.datacollection = {
                labels: this.dates,
                datasets: [
                  {
                    label,
                    backgroundColor,
                    data: this.noOfTix,
                  },
                ],
              };
              console.log("datacollection");
              console.log(this.datacollection);
            }
            // console.log("venuename"+data);
            //  console.log(data);
            let ticketHtml = "";
            const clonedData = _.clone(data);
            const shortData = _.orderBy(clonedData, ["createdat"], ["desc"]);
            shortData.forEach((tr) => {
              ticketHtml += `<tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.venuename
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.city
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.state
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.avgvalue
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.availabletixs
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.tixsoldindaterange
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.avgsaleprice
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${this.getCST(
                  tr.createdat,
                  "MMMM Do YYYY"
                )}
                </td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${this.getCST(
                  tr.createdat,
                  "hh:mm A (Z)"
                )}
                </td>
              </tr>`;
            });
            document.getElementById("tixChartList").innerHTML = ticketHtml;
          } else {
            alert(res.data.msg ? res.data.msg : "Try again!");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Try again later.");
        });
    },
    musictop100usa() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];
      document.getElementById("music100usa").innerHTML = "Loading...";

      this.$axios
        .get(`https://varilytics.com/spaceseat/musicapple.php?term=${event}`)
        .then((res) => {
          // console.log(res);
          document.getElementById("music100usa").innerHTML = res.data;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("music100usa").innerHTML = "Try again later.";
        });
    },

    musictop100global() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];

      document.getElementById("music100global").innerHTML = "Loading...";

      this.$axios
        .get(`https://varilytics.com/spaceseat/musicappleg.php?term=${event}`)
        .then((res) => {
          // console.log(res);
          document.getElementById("music100global").innerHTML = res.data;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("music100global").innerHTML =
            "Try again later.";
        });
    },
    musicappletop() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];

      document.getElementById("musicappletop").innerHTML =
        "Please wait. This process can take 2 to 4 minutes.";

      this.$axios
        .get(`https://varilytics.com/spaceseat/musicappletop.php?term=${event}`)
        .then((res) => {
          //console.log(res);
          document.getElementById("musicappletop").innerHTML = res.data;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("musicappletop").innerHTML =
            "Try again later.";
        });
    },
    musicappleint() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];

      document.getElementById("musicappleinternationaltop").innerHTML =
        "Loading...";
      this.$axios
        .get(
          `https://varilytics.com/spaceseat/musicappleinternationaltop.php?term=${event}`
        )
        .then((res) => {
          // console.log(res);
          document.getElementById("musicappleinternationaltop").innerHTML =
            res.data;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("musicappleinternationaltop").innerHTML =
            "Try again later.";
        });
    },
    trend7d() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];
      this.showCompTrend7d = false;
      document.getElementById("intbsrd-ifrm").innerHTML = "Loading...";
      this.$axios
        .get(`https://varilytics.com/spaceseat/trend7dg.php?event=${event}`)
        .then((res) => {
          this.trendsData = res.data;

          const labels = this.trendsData.map((item) => item[""]); // Extract state names
          const dData = this.trendsData.map((item) => item[event]); // Extract Shane Gillis data

          this.dcompTrend7d = {
            labels: labels,
            datasets: [
              {
                label: event,
                data: dData,
                backgroundColor: "rgba(255, 99, 132, 1)",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
              },
            ],
          };
          document.getElementById("intbsrd-ifrm").innerHTML = "Results";

          this.showCompTrend7d = true;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("intbsrd-ifrm").innerHTML =
            "Try again later.";
        });
    },
    trend1y() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];
      this.showCompTrend1y = false;
      document.getElementById("intbsrm-ifrm").innerHTML = "Loading...";
      this.$axios
        .get(`https://varilytics.com/spaceseat/trend1yg.php?event=${event}`)
        .then((res) => {
          // console.log(res); today 12-m
          // document.getElementById("intbsrm-ifrm").innerHTML = res.data;
          this.trendsData = res.data;

          const labels = this.trendsData.map((item) => item[""]); // Extract state names
          const dData = this.trendsData.map((item) => item[event]); // Extract Shane Gillis data

          this.dcompTrend1y = {
            labels: labels,
            datasets: [
              {
                label: event,
                data: dData,
                backgroundColor: "rgba(255, 99, 132, 1)",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
              },
            ],
          };
          document.getElementById("intbsrm-ifrm").innerHTML = "Results";

          this.showCompTrend1y = true;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("intbsrm-ifrm").innerHTML =
            "Try again later.";
        });
    },
    trend5y() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];
      this.showCompTrend5y = false;
      document.getElementById("intbsr-ifrm").innerHTML = "Loading...";
      this.$axios
        .get(`https://varilytics.com/spaceseat/trend5yg.php?event=${event}`)
        .then((res) => {
          // console.log(res);
          // document.getElementById("intbsr-ifrm").innerHTML = res.data;
          this.trendsData = res.data;

          const labels = this.trendsData.map((item) => item[""]); // Extract state names
          const dData = this.trendsData.map((item) => item[event]); // Extract Shane Gillis data

          this.dcompTrend5y = {
            labels: labels,
            datasets: [
              {
                label: event,
                data: dData,
                backgroundColor: "rgba(255, 99, 132, 1)",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
              },
            ],
          };
          document.getElementById("intbsr-ifrm").innerHTML = "Results";
          this.showCompTrend5y = true;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("intbsr-ifrm").innerHTML = "Try again later.";
        });
    },
    compareInterest() {
      // alert(this.aname);
      if (this.aname != "") {
        let event = this.$route.params.eventName;
        console.log(event);
        event = event.split("&");
        event = event[0].split(",");
        event = event[0].split("-");
        event = event[0].split("(");
        event = event[0];

        document.getElementById("intot-comp").innerHTML = "Loading...";

        this.$axios
          .get(
            `https://varilytics.com/spaceseat/interest_compare.php?event=${event}&aname=${this.aname}`
          )
          .then((res) => {
            //console.log(res);
            this.trendsData = res.data;
            // const ctx = document.getElementById("trendsChart").getContext("2d");

            const labels = this.trendsData.map((item) => item[""]); // Extract state names
            const shaneGillisData = this.trendsData.map((item) => item[event]); // Extract Shane Gillis data
            const taylorSwiftData = this.trendsData.map(
              (item) => item[this.aname]
            ); // Extract Taylor Swift data

            this.dcomp = {
              labels: labels,
              datasets: [
                {
                  label: event,
                  data: shaneGillisData,
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderColor: "rgba(255, 99, 132, 1)",
                  fill: false,
                },
                {
                  label: this.aname,
                  data: taylorSwiftData,
                  backgroundColor: "rgba(54, 162, 235, 1)",
                  borderColor: "rgba(54, 162, 235, 1)",
                  fill: false,
                },
              ],
            };
            document.getElementById("intot-comp").innerHTML = "Results";

            this.showComp = true;
          })
          .catch((err) => {
            console.log(err);
            document.getElementById("intot-comp").innerHTML =
              "Try again later.";
            this.showComp = false;
          });
      } else {
        alert("Please enter a artist name");
      }
    },
    interestOverTime() {
      let event = this.$route.params.eventName;
      event = event.split("&");
      event = event[0].split(",");
      event = event[0].split("-");
      event = event[0].split("(");
      event = event[0];
      this.showCompIoverTime = false;
      document.getElementById("intot-ifrm").innerHTML = "Loading...";
      this.$axios
        .get(
          `https://varilytics.com/spaceseat/interest_over_timeg.php?event=${event}`
        )
        .then((res) => {
          // console.log(res);
          // document.getElementById("intot-ifrm").innerHTML = res.data;
          this.trendsData = res.data;

          const labels = this.trendsData.map((item) => item["date"]); // Extract state names
          const dData = this.trendsData.map((item) => item[event]); // Extract Shane Gillis data

          this.dcompIoverTime = {
            labels: labels,
            datasets: [
              {
                label: event,
                data: dData,
                backgroundColor: "rgba(255, 99, 132, 1)",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
              },
            ],
          };
          document.getElementById("intot-ifrm").innerHTML = "Results";
          this.showCompIoverTime = true;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("intot-ifrm").innerHTML = "Try again later.";
        });
    },
    chatgpt() {
      // console.log("result test1234")
      // console.log(this.$route.params.eventName)
      // let event = this.$route.params.eventName;
      document.getElementById("chatgptdatapromt1").innerHTML = "Loading...";
      document.getElementById("chatgptdatapromt2").innerHTML = "Loading...";
      // event =  event.replaceAll('&', '');
      // event =  event.replaceAll(',', '');
      // // event =  event.replaceall(',', '');
      var data12 = `Imagine you are a talent manager. When prompted with a name, return a list of the top 10 us and Canada cities you think they'd sell a live event well in. Include with each city a 5-10 word summary of why you believe they would sell well. Include complete statistics for the racial demographic.  Include complete statistics for the population.  Include average salary. Score Each City from 0 to 100 in how confident they would sell well there. Format the list to look like a chart and sort from most to least confident. If multiple names are provided assume it is a double headliner tour. ${this.$route.params.eventName}`;
      document.getElementById("chatgptdatapromt1").innerHTML = "Loading...";
      // var data12 = "test";
      this.$axios
        .get(`https://spaceseats.io/api/ticket/chatgtp?data=${data12}`)
        .then((res) => {
          // console.log("result test12340")
          // console.log(res.data.data)
          let ress = res.data.data;
          ress = ress.replaceAll("\n", "");
          const split = ress.split("|");
          var html = "<table>";
          var y = 1;
          for (let u = 1; u < split.length; u++) {
            if (u >= 1 && u <= 6) {
              if (y != 6) {
                if (u == 1) {
                  html += `<tr>`;
                }
                html += `<th>${split[u]}</th>`;
                if (u == 5) {
                  html += `</tr>`;
                }

                y++;
              } else {
                y = 1;
              }
            } else {
              // console.log(y);
              if (y != 6) {
                if (y == 1) {
                  html += `<tr>`;
                }
                html += `<td>${split[u]}</td>`;
                if (y == 5) {
                  html += `</tr>`;
                }

                y++;
              } else {
                y = 1;
              }
            }
          }
          html += "</table>";
          document.getElementById("chatgptdatapromt1").innerHTML = html;
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("chatgptdatapromt1").innerHTML =
            "Try again later.";
        });

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://app.ticketmaster.com/discovery/v2/events.json?keyword=${this.$route.params.eventName}&apikey=C1zETHjzG9nLxALw3JlbfE4kfWx7TVXW`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          // console.log(result);
          // console.log(this.$route.params.eventName);
          var data120 = `Imagine you are a talent manager. When prompted with an artist and list of cities please sort the list of cities from most to least confident and give each city a confidence score from 1-100. Along with the confidence score please rationalize your logic with reasoning including, demographic, population within 20 mile radius, music style, language and economic factors. Limit reasoning to 15 words.  Format the response to look like a table. As a footnote please include a short biography on the ${result._embedded.events[0]._embedded.attractions[0].name}.`;

          // var data12 = "test";
          this.$axios
            .get(`https://spaceseats.io/api/ticket/chatgtp?data=${data120}`)
            .then((res) => {
              let ress = res.data.data;
              ress = ress.replaceAll("\n", "");
              const split = ress.split("|");
              var html = "<table>";
              var y = 1;
              for (let u = 1; u < split.length; u++) {
                if (u >= 1 && u <= 4) {
                  if (y != 4) {
                    if (u == 1) {
                      html += `<tr>`;
                    }
                    html += `<th>${split[u]}</th>`;
                    if (u == 3) {
                      html += `</tr>`;
                    }

                    y++;
                  } else {
                    y = 1;
                  }
                } else {
                  if (y != 4) {
                    if (y == 1) {
                      html += `<tr>`;
                    }
                    html += `<td>${split[u]}</td>`;
                    if (y == 3) {
                      html += `</tr>`;
                    }

                    y++;
                  } else {
                    y = 1;
                  }
                }
              }
              html += "</table>";
              document.getElementById("chatgptdatapromt2").innerHTML = html;
            })
            .catch((err) => {
              console.log(err);
              document.getElementById("chatgptdatapromt2").innerHTML =
                "Try again later.";
            });
        });
    },

    getTodaySale() {
      this.isGraphShow = false;
      this.loading = "";
      this.$axios
        .get(`${this.$api}/ticket/today-sale/${this.$route.params.eventName}`)
        .then((res) => {
          // console.log(res);
          if (res.data.success) {
            const data = res.data.data;
            if (data.length === 0) {
              this.loading = "No data found";
              return;
            }
            if (!res.data.success) {
              this.loading = "No data found";
              return;
            }
            this.revenueDates = data.map((v) =>
              moment(v.createdattime, "HH:mm:ss").format("LT")
            );
            this.revenue = data.map((v) => v.Revenue);
            this.datacollection = {
              labels: this.revenueDates,
              datasets: [
                {
                  label: "Today Revenue",
                  backgroundColor: "#8789F2",
                  data: this.revenue,
                },
              ],
            };
            const clonedData = _.clone(data);
            const shortData = _.orderBy(clonedData, ["createdat"], ["desc"]);
            let ticketHtml = "";
            shortData.forEach((tr) => {
              Object.assign(
                ...Object.keys(tr).map((key) => ({
                  [key.toLowerCase()]: tr[key],
                }))
              );

              ticketHtml += `<tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
               <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                 tr.venuename
               }
                </td>
               <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                 tr.city
               }
                </td>
               <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                 tr.state
               }
                </td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.createdatdate
                }
                </td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${moment(
                  tr.createdattime,
                  "HH:mm:ss"
                ).format("LT")}
                </td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.NewTixSoldInDateRange
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">$${tr.NewAvgSalePrice.toFixed(
                  2
                )}</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">$${tr.Revenue.toFixed(
                  2
                )}</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.NewAvailableTixs
                }</td>
              </tr>`;
            });
            setTimeout(() => {
              document.getElementById(
                "tixChartListToday"
              ).innerHTML = ticketHtml;
            }, 1000);
          } else {
            alert(res.data.msg ? res.data.msg : "Try again");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Try again later.");
        });
    },
    venuename() {
      // var evennam= this.$route.params.eventName
      // evennam = evennam.replace(' ', '%20');
      //console.log("col")
      // var requestOptions = {
      //   method: 'GET',
      //   redirect: 'follow'
      // };
      // fetch("https://varilytics.com/api/venname/?name=Laugh%20Out%20Loud%20Comedy%20Club", requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));
      // this.$axios
      //   .get("https://varilytics.com/api/venname/", {
      //     mode: 'no-cors',
      //     header: {
      //       'Access-Control-Allow-Origin':'*',
      //     }
      //   })
      //   .then(res => {
      //     console.log(res);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     alert("Something went wrong, please try again");
      //   });
    },
    moreshow() {
      var html1;
      var evennam = this.$route.params.eventName;
      var f = 1;
      var j = 1;
      var g = 1;
      evennam = evennam.replace(" ", "%20");
      if (evennam == "Hardy") {
        html1 = "<b> Accessible Tickets :- </b> ";
        html1 +=
          " Ticketmaster, on behalf of its clients, strives to make it as easy as possible to purchase accessible seating tickets for venues across the country. Most venues offer accessible seating areas to accommodate their guests. Availability and type of accessible seating tickets will vary based on each venue's policies and/or the type of event taking place. Note: Venues are solely responsible for establishing their own accessible seating ticket policies. Some venues sell all or a portion of their accessible seating tickets only through their box office and not through Ticketmaster. For further information on purchasing accessible tickets, visit our ";

        html1 += "<b> Important Event Info :- </b> ";

        html1 += `Clear Bag Policy - Clear Bag no larger than than 12"X6"x12", Small Clutch purse no larger than 4.4"X6.5", or Clear Plastic Storage Bag no larger than one Gallon resealable.`;
      } else if (evennam == "George%20Strait") {
        html1 = "<b> Accessible Tickets :- </b> ";
        html1 +=
          "Ticketmaster, on behalf of its clients, strives to make it as easy as possible to purchase accessible seating tickets for venues across the country. Most venues offer accessible seating areas to accommodate their guests. Availability and type of accessible seating tickets will vary based on each venue's policies and/or the type of event taking place. Note: Venues are solely responsible for establishing their own accessible seating ticket policies. Some venues sell all or a portion of their accessible seating tickets only through their box office and not through Ticketmaster. For further information on purchasing accessible tickets, visit our FAQ";

        html1 += "<b> Important Event Info :- </b> ";

        html1 += `To allow for more Card Members to enjoy the show, American Express has set a two-order limit for this offer. This limit applies across all Cards associated with all American Express accounts. Prepaid Cards are not eligible.`;
      } else {
        // console.log("https://app.ticketmaster.com/discovery/v2/events"+evennam);
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        fetch("https://varilytics.com/api/showmore/" + evennam, requestOptions)
          .then((response) => response.text())
          .then((res) => {
            res = JSON.parse(res);
            // console.log(res)
            for (var i = 0; i < res._embedded.events.length; i++) {
              if (res._embedded.events[i].pleaseNote) {
                if (f == 1) {
                  html1 = "<b> Important Event Info :- </b> ";
                  f++;
                } else {
                  html1 += "<br />";
                }
                html1 += res._embedded.events[i].pleaseNote;
              }
            }
            html1 += "<hr />";
            for (i = 0; i < res._embedded.events.length; i++) {
              if (res._embedded.events[i].info) {
                if (j == 1) {
                  html1 += "<b> Additional Info :- </b> ";
                  j++;
                } else {
                  html1 += "<br />";
                }
                html1 += res._embedded.events[i].info;
              }
            }
            html1 += "<hr />";
            for (i = 0; i < res._embedded.events.length; i++) {
              if (res._embedded.events[i]._embedded.attractions[0].name) {
                for (
                  var k = 0;
                  k < res._embedded.events[i]._embedded.attractions.length;
                  k++
                ) {
                  if (res._embedded.events[i]._embedded.attractions[k].name) {
                    if (g == 1) {
                      html1 += "<b> Line Up :- </b> ";
                      g++;
                    } else {
                      html1 += "<br />";
                    }
                    html1 +=
                      res._embedded.events[i]._embedded.attractions[k].name;
                  }
                }
                break;
              }
            }
          })
          .catch((error) => console.log("error", error));
      }
      //console.log(html1)
      document.getElementById("moreinfo").innerHTML = html1;
    },
    googleresultapi() {
      document.getElementById("googleresultapi").innerHTML = "Loading...";
      let event = this.$route.params.eventName;
      event = event.replaceAll("Conference", "");
      event = event.replaceAll("Semifinals", "");
      event = event.replaceAll("&", "");
      event = event.replaceAll(",", "");
      if (event.split(":")) {
        event = event.split(":");
        event = event[0];
      }
      if (event.split("-")) {
        event = event.split("-");
        event = event[0];
      }
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://spaceseats.io/api/ticket/googleresultapi?name=${event}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          var html = "<html>" + result.data + "</html>";
          // console.log(html)
          document.getElementById("googleresultapi").innerHTML = html;
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("googleresultapi").innerHTML =
            "Try again later.";
        });
    },

    Billboardhot100() {
      document.getElementById("Billboardhot100").innerHTML = "Loading...";
      var myHeaders = new Headers();
      myHeaders.append(
        "X-RapidAPI-Key",
        "c1c2585ee6msh86008b43d6ed6fcp1f4ce3jsn6d289fe354e0"
      );
      myHeaders.append("X-RapidAPI-Host", "billboard-api2.p.rapidapi.com");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://billboard-api2.p.rapidapi.com/hot-100?date=2023-05-05&range=1-100",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          var html = "";

          for (var i = 1; i <= 10; i++) {
            html += '<div class="card">';
            html += '<div class="container">';
            html += "<h4> Name :- <b>" + result.content[i].artist + "</b></h4>";
            html += "<p> <b> Title :- </b>" + result.content[i].title + "</p>";
            html += "<p> <b> Rank :- </b>" + result.content[i].rank + "</p>";
            html += "</div>";
            html += "</a>";
            html += "</div>";
          }

          document.getElementById("Billboardhot100").innerHTML = html;
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("Billboardhot100").innerHTML =
            "Try again later.";
        });
    },

    Billboard200() {
      document.getElementById("Billboard200").innerHTML = "Loading...";
      var myHeaders = new Headers();
      myHeaders.append(
        "X-RapidAPI-Key",
        "c1c2585ee6msh86008b43d6ed6fcp1f4ce3jsn6d289fe354e0"
      );
      myHeaders.append("X-RapidAPI-Host", "billboard-api2.p.rapidapi.com");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://billboard-api2.p.rapidapi.com/billboard-200?date=2023-06-10&range=1-100",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          var html = "";

          for (var i = 1; i <= 10; i++) {
            html += '<div class="card">';
            html += '<div class="container">';
            html += "<p> <b> Title :- </b>" + result.content[i].album + "</p>";
            html += "<p> <b> Rank :- </b>" + result.content[i].rank + "</p>";
            html += "</div>";
            html += "</a>";
            html += "</div>";
          }

          document.getElementById("Billboard200").innerHTML = html;
          console.log(html);
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("Billboard200").innerHTML =
            "Try again later.";
        });
    },

    Artist100() {
      document.getElementById("Artist100").innerHTML = "Loading...";
      var myHeaders = new Headers();
      myHeaders.append(
        "X-RapidAPI-Key",
        "c1c2585ee6msh86008b43d6ed6fcp1f4ce3jsn6d289fe354e0"
      );
      myHeaders.append("X-RapidAPI-Host", "billboard-api2.p.rapidapi.com");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://billboard-api2.p.rapidapi.com/artist-100?date=2023-06-10&range=1-10",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          var html = "";

          for (var i = 1; i <= 10; i++) {
            html += '<div class="card">';
            html += '<div class="container">';
            html += "<p> <b> Name :- </b>" + result.content[i].artist + "</p>";
            html += "<p> <b> Rank :- </b>" + result.content[i].rank + "</p>";
            html += "</div>";
            html += "</a>";
            html += "</div>";
          }

          document.getElementById("Artist100").innerHTML = html;
          console.log(html);
        })
        .catch((error) => {
          console.log("error", error);
          document.getElementById("Artist100").innerHTML = "Try again later.";
        });
    },

    googleresultapidata() {
      let event = this.$route.params.eventName;
      event = event.replaceAll("Conference", "");
      event = event.replaceAll("Semifinals", "");
      event = event.replaceAll("&", "");
      event = event.replaceAll(",", "");
      if (event.split(":")) {
        event = event.split(":");
        event = event[0];
      }
      if (event.split("-")) {
        event = event.split("-");
        event = event[0];
      }

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://spaceseats.io/api/ticket/googleresultapidata?name=${event}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);

          var html = "<html>" + result.data + "</html>";
          // console.log(html)
          document.getElementById("googleresultapidata").innerHTML = html;
        })
        .catch((error) => console.log("error", error));
    },

    ContainsAny(str, items) {
      for (var i in items) {
        var item = items[i];
        if (str.indexOf(item) > -1) {
          return true;
        }
      }
      return false;
    },

    getCapacity(vid, qury) {
      document.getElementById(vid).innerHTML = "Fetching...";

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&prop=info&inprop=url&utf8=&format=json&origin=*&srsearch=${qury}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          let eCheck = false;
          // var html = "<html>" + result.query?.search[0]?.snippet + "</html>";
          var html = "<html>";

          result.query?.search.forEach((element) => {
            if (
              this.ContainsAny(element.snippet, [
                "seat",
                "seats",
                "capacity",
                "seating",
              ])
            ) {
              html += element.snippet;
              eCheck = true;
            }
          });
          html += "</html>";
          if (eCheck) {
            document.getElementById(vid).innerHTML = html;
          } else {
            document.getElementById(vid).innerHTML =
              "<html> Not Found! </html>";
          }
        })
        .catch((error) => (document.getElementById(vid).innerHTML = error));
    },
    // getKeywordStatsModal(k, keywords_array) {
    //   console.log(keywords_array[0].name);
    //   //console.log(keywords_array.length);

    //   for (var i = 0; i < keywords_array.length; i++) {
    //     this.csk.push(keywords_array[i].name);
    //   }
    //   console.log(this.csk);

    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow",
    //   };
    //   fetch(
    //     `https://varilytics.com/spaceseat/gads/get_ads.php?keywords=${this.csk}`,
    //     requestOptions
    //   )
    //     .then((response) => response.text())
    //     .then((result) => {
    //       // console.log(result);
    //       document.getElementById("stats-modal-content" + k).innerHTML = result;
    //       // document.getElementById("stats-modal-close" + k).className =
    //       // "visible text-center bg-gray-100 bg-opacity-50 sticky bottom-0";
    //     });
    // },
    youtube() {
      var evennam = this.$route.params.eventName;
      document.getElementById("youtube").innerHTML = "Loading...";
      this.$axios
        .get("https://varilytics.com/api/youtube/" + evennam)
        .then((res) => {
          var data = res.data;
          var html = "";
          var image;
          for (var i = data.length - 1; i > 0; i--) {
            // console.log(data[i].thumbnails[0]);
            if (data[i].type == "video") {
              image = data[i].thumbnail.url;
            } else if (data[i].type == "playlist") {
              image = data[i].firstVideo.bestThumbnail.url;
            }

            // let number = ;
            //  var formattedNumber1;
            if (new Intl.NumberFormat("en-US").format(data[i].views) != "NaN") {
              html += '<div class="card">';
              html += '<a href="' + data[i].url + '" target="_blank">';
              html +=
                '<img src="' + image + '" style="height:100px; width:100px;" >';
              html += '<div class="container">';
              html += "<h4><b>" + data[i].title + "</b></h4>";
              html +=
                "<p> <b> Views:</b>" +
                new Intl.NumberFormat("en-US").format(data[i].views) +
                "</p>";
              html += "</div>";
              html += "</a>";
              html += "</div>";
            }
          }
          //console.log(html);
          document.getElementById("youtube").innerHTML = html;
        })
        .catch((err) => {
          console.log(err);
          // alert("Try again later.");
          document.getElementById("youtube").innerHTML = "Try again later.";
        });
    },
    openUrlWithLazy(id, url) {
      console.log(url);
      document.getElementById(id).innerHTML =
        '<iframe id="iframe" src="' +
        url +
        '" width="100%" height="650"></iframe>';
      // document.getElementById('loadwiki').innerHTML = '<iframe v-lazy="https://en.wikipedia.org/wiki/'+url+'" width="100%" height="650" > </iframe>';
    },
    spotify() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const options = {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "91f9afdd3amshdc101e4f83325edp176061jsncbc4efe04ae4",
          "X-RapidAPI-Host": "spotify23.p.rapidapi.com",
        },
      };

      fetch("https://spaceseats.io/api/ticket/gettoken", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          document.getElementById("spotify-date").innerHTML = "Loading...";
          document.getElementById("spotify-data").innerHTML = "Loading...";
          result = JSON.parse(result);

          var evennam = this.$route.params.eventName;
          var myHeaders = new Headers();
          myHeaders.append("Authorization", "Bearer " + result.token);

          var requestOptions0 = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          fetch(
            "https://api.spotify.com/v1/search?q=" +
              evennam +
              "&type=album%2Ctrack",
            requestOptions0
          )
            .then((response) => response.text())
            .then((result) => {
              result = JSON.parse(result);
              var data = result.albums.items;

              var arr = [];

              for (var i = 0; i < data.length; i++) {
                arr.push(data[i].release_date);
              }

              arr.sort().reverse();

              var html = "";
              for (i = 0; i < arr.length; i++) {
                for (var j = 0; j < data.length; j++) {
                  if (data[j].release_date === arr[i]) {
                    html += '<div class="card">';
                    html +=
                      '<a href="' +
                      data[j].external_urls.spotify +
                      '" target="_blank">';
                    html +=
                      '<img src="' +
                      data[j].images[2].url +
                      '" style="height:100px; width:100px;" >';
                    html += '<div class="container">';
                    html += "<h4><b>" + data[j].name + "</b></h4>";
                    html +=
                      "<p> <b> Release Date:</b>" +
                      data[j].release_date +
                      "</p>";
                    html += "</div>";
                    html += "</a>";
                    html += "</div>";
                  }
                }
              }

              document.getElementById("spotify-date").innerHTML = html;
              //console.log(html);

              html = " ";

              fetch(
                `https://spotify23.p.rapidapi.com/artist_overview/?id=${data[0].artists[0].id}`,
                options
              )
                .then((response) => response.text())
                .then((result) => {
                  result = JSON.parse(result);

                  let data_html =
                    "<p style='margin-left:20px;'> Name :- " +
                    data[0].artists[0].name +
                    "</p>";
                  data_html +=
                    "<p style='margin-left:20px;'> followers :- " +
                    result.data.artist.stats.followers +
                    "</p>";

                  data_html +=
                    "<p style='margin-left:20px;'> Monthly Listeners :- " +
                    result.data.artist.stats.monthlyListeners +
                    "</p>";

                  let i;
                  let idcount = 1;
                  if (result.data.artist.stats.topCities.items.length != 0) {
                    data_html += "<p style='margin-top:20px;'>";
                    for (
                      i = 0;
                      i < result.data.artist.stats.topCities.items.length;
                      i++
                    ) {
                      data_html += "<p> Top:- " + idcount + "<br>";
                      data_html +=
                        "<p style='margin-left:20px;'> City Name :- " +
                        result.data.artist.stats.topCities.items[i].city +
                        "</p>";
                      data_html +=
                        "<p style='margin-left:20px;'> country Code :- " +
                        result.data.artist.stats.topCities.items[i].country +
                        "</p>";
                      data_html +=
                        "<p style='margin-left:20px;'> Number Of Listeners :- " +
                        result.data.artist.stats.topCities.items[i]
                          .numberOfListeners +
                        "</p>";
                      data_html +=
                        "<p style='margin-left:20px;'> region :- " +
                        result.data.artist.stats.topCities.items[i].region +
                        "</p>";

                      idcount++;

                      data_html += "</p>";
                    }
                    data_html += "</p>";
                  }
                  if (
                    result.data.artist.profile.externalLinks.items.length != 0
                  ) {
                    data_html += "<p style='margin-top:20px;'> Social Media:-";
                    for (
                      i = 0;
                      i < result.data.artist.profile.externalLinks.items.length;
                      i++
                    ) {
                      data_html +=
                        "<p style='margin-left:20px;'> " +
                        result.data.artist.profile.externalLinks.items[i].name +
                        " :- <a href=" +
                        result.data.artist.profile.externalLinks.items[i].url +
                        ">" +
                        result.data.artist.profile.externalLinks.items[i].url +
                        "</a></p>";
                    }
                    data_html += "</p>";
                  }
                  document.getElementById("spotify-data").innerHTML = data_html;
                  // console.log(data_html)
                });
            })
            .catch((err) => {
              console.log(err);
              // alert("Try again later.");
              document.getElementById("spotify-date").innerHTML =
                "Try again later.";
              document.getElementById("spotify-data").innerHTML =
                "Try again later.";
            });
        });
    },
  },
};
</script>
<style>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

iframe {
  height: 650px;
  width: 100%;
}
.vc-container {
  height: 650px;
  width: 100%;
}
.fs-small {
  font-size: small;
}
</style>
